import { useEffect, useState } from "react";
import { useParams, NavLink } from "react-router-dom";

import { customerApi } from "../api/customerApi";

import Success from "../components/Success";
import Error from "../components/Error";

const initialInteractionState = {
  remarks: "",
  interactionDate: "",
};

function LogInteraction() {
  const { id } = useParams();
  const [customer, setCustomer] = useState({});
  const [interaction, setInteraction] = useState(initialInteractionState);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadCustomer(id);
  }, [id]);

  const loadCustomer = async (id) => {
    try {
      const response = await customerApi.get(`/customers/${id}`);
      setCustomer(response.data);
      setError(null);
    } catch (error) {
      setError(error.response.data.message);
    }
  };

  const handleChangeInteraction = (e) => {
    setInteraction((interaction) => {
      return { ...interaction, [e.target.name]: e.target.value };
    });
  };

  const handleAddInteraction = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const newInteraction = {
        remarks: e.target.remarks.value,
        interactionDate: e.target.interactionDate.value,
      };
      await customerApi.post(`/customers/${id}/interactions`, newInteraction);
      setSuccess(true);
      setInteraction(initialInteractionState);
      setError(null);
    } catch (error) {
      setSuccess(false);
      if (error.response.status === 400) setError(error.response.data.message);
      else setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ width: 600 }}>
      <h1>Log Interaction</h1>
      <p style={{ marginBottom: 20 }}>
        Customer:{" "}
        <NavLink to={`/details/${customer.id}`}>
          {customer.firstName} {customer.lastName}
        </NavLink>
      </p>

      {success && <Success message="Interaction logged successfully." />}
      {error && <Error message={error} />}

      <form className="interaction-form" onSubmit={handleAddInteraction}>
        <label htmlFor="remarks">Remarks:</label>
        <textarea
          id="remarks"
          name="remarks"
          type="text"
          placeholder="Enter remarks here..."
          onChange={handleChangeInteraction}
          value={interaction.remarks}
        />
        <label htmlFor="interactionDate">Interaction Date:</label>
        <input
          id="interactionDate"
          name="interactionDate"
          type="date"
          placeholder="Enter interaction date here..."
          onChange={handleChangeInteraction}
          value={interaction.interactionDate}
        />
        <button disabled={isLoading}>
          {isLoading ? "Adding ..." : "Add Interaction"}
        </button>
      </form>
    </div>
  );
}

export default LogInteraction;
