import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
    // stripePromise = loadStripe('pk_test_51O31pSBNCyfrL1V5QIhjaUgkKg25R7llENIyXu0yDOnItwVBaqcbhUjms5xQURtTwQCyuyUXf0JxgI7LAuaw96am00yxSpZlP3');
  }
  return stripePromise;
};

export default getStripe;

// https://www.freecodecamp.org/news/react-stripe-payments/