import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import LogInteraction from "./pages/LogInteraction";
import CustomerDetails from "./pages/CustomerDetails";
import RootLayout from "./layouts/RootLayout";
import Customers from "./pages/Customers";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import Payment from "./pages/Payment";
import AddCustomer from "./pages/AddCustomer";



function App() {
  console.log(process.env.NODE_ENV);
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<Home />} />
          <Route path="customers" element={<Customers />} />
          <Route path="log-interaction/:id" element={<LogInteraction />} />
          <Route path="details/:id" element={<CustomerDetails />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="contact" element={<Contact />} />
          <Route path="payment" element={<Payment />} />
          <Route path="add-customer" element={<AddCustomer />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;

// https://trekinbami.medium.com/using-environment-variables-in-react-6b0a99d83cf5