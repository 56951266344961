import getStripe from '../lib/getStripe';

function Payment() {
  async function handleCheckout() {
    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: process.env.REACT_APP_NEXT_PUBLIC_STRIPE_PRICE_ID,
          quantity: 1,
        },
      ],
      mode: 'payment',
    //   mode: 'subscription',
      successUrl: `https://www.deliveranz.com.sg`,
      cancelUrl: `https://www.deliveranz.com.sg`,
      customerEmail: 'customer@email.com',
    });
    console.warn(error.message);
  }

  return (
  <>
    <h1>Payment Page</h1>
    <button onClick={handleCheckout}>Pay for 1 coffee</button>
  </>
  )
}  
  export default Payment;