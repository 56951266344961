import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { customerApi } from "../api/customerApi";

import Loading from "../components/Loading";
import Error from "../components/Error";

const initialCustomerState = {
  firstName: "",
  lastName: "",
  email: "",
  contactNo: "",
  jobTitle: "",
  yearOfBirth: "",
};

function CustomerDetails() {
  const [customer, setCustomer] = useState(initialCustomerState);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    loadCustomer(id);
  }, [id]);

  const loadCustomer = async (id) => {
    try {
      setIsLoading(true);
      const response = await customerApi.get(`/customers/${id}`);
      console.log(response.data);
      setCustomer(response.data);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeCustomer = (e) => {
    setCustomer((customer) => {
      return { ...customer, [e.target.name]: e.target.value };
    });
  };

  if (isLoading) return <Loading />;

  return (
    <div style={{ width: 600 }}>
      <h1>Customer Details</h1>
      {error && <Error message={error} />}
      <div className="grid-container">
        <label htmlFor="firstName">First Name</label>
        <input
          id="firstName"
          name="firstName"
          type="text"
          value={customer.firstName}
          onChange={handleChangeCustomer}
          disabled
        />
        <label htmlFor="lastName">Last Name</label>
        <input
          id="lastName"
          name="lastName"
          type="text"
          value={customer.lastName}
          onChange={handleChangeCustomer}
          disabled
        />
        <label htmlFor="email">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          value={customer.email}
          onChange={handleChangeCustomer}
          disabled
        />
        <label htmlFor="contactNo">Contact No.</label>
        <input
          id="contactNo"
          name="contactNo"
          type="text"
          value={customer.contactNo}
          onChange={handleChangeCustomer}
          disabled
        />
        <label htmlFor="jobTitle">Job Title</label>
        <input
          id="jobTitle"
          name="jobTitle"
          type="text"
          value={customer.jobTitle}
          onChange={handleChangeCustomer}
          disabled
        />
        <label htmlFor="yearOfBirth">Year of Birth</label>
        <input
          id="yearOfBirth"
          name="yearOfBirth"
          type="text"
          value={customer.yearOfBirth}
          onChange={handleChangeCustomer}
          disabled
        />
      </div>
      <h2 style={{ marginTop: 20 }}>Interactions</h2>
      <div style={{ marginBottom: 20 }}>
        {customer.interactions?.length === 0 && (
          <p>
            No interactions with{" "}
            <strong>
              {customer.firstName} {customer.lastName}
            </strong>
            .
          </p>
        )}
        {customer.interactions?.length > 0 && (
          <ul style={{ marginLeft: 20 }}>
            {customer.interactions.map((interaction) => (
              <li key={interaction.id}>
                {interaction.interactionDate} - {interaction.remarks}
              </li>
            ))}
          </ul>
        )}
      </div>
      <button onClick={() => navigate(`/log-interaction/${customer.id}`)}>
        Add Interaction
      </button>
    </div>
  );
}

export default CustomerDetails;
