import { ClimbingBoxLoader } from "react-spinners";

function Loading() {
  return (
    <div
      style={{
        width: 500,
        height: 500,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ClimbingBoxLoader color="#333" />
    </div>
  );
}

export default Loading;
