import DeliveranzLogo from "../assets/deliveranz-logo.png";

function Header() {
  return (
    <header className="header">
      <img
        src={DeliveranzLogo}
        alt="Deliveranz Logo"
        height={40}
        style={{ marginLeft: 20, borderRadius: 20 }}
      />

      <input
        type="text"
        className="search-input"
        placeholder="Search customers ..."
      />
    </header>
  );
}

export default Header;
