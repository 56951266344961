import { useState } from "react";

// import { addCustomer } from "../api/crmApi";
import { customerApi } from "../api/customerApi";

import Success from "../components/Success";
import Error from "../components/Error";

const initialCustomerState = {
  firstName: "",
  lastName: "",
  email: "",
  contactNo: "",
  jobTitle: "",
  yearOfBirth: "",
};

function AddCustomer() {
  const [newCustomer, setNewCustomer] = useState(initialCustomerState);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCustomerChange = (e) => {
    setNewCustomer((customer) => {
      return { ...customer, [e.target.name]: e.target.value };
    });
  };

  const handleAddCustomer = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await customerApi.post("/customers", newCustomer);
      setSuccess(true);
      setError(null);
      setNewCustomer(initialCustomerState);
    } catch (error) {
      if (error.response.status === 400) setError(error.response.data.message);
      else setError(error.message);
      setSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ width: 600 }}>
      <h1>Add Customer</h1>
      {success && <Success message="Customer added successfully." />}
      {error && <Error message={error} />}
      <form className="grid-container" onSubmit={handleAddCustomer}>
        <label htmlFor="firstName">First Name</label>
        <input
          id="firstName"
          name="firstName"
          type="text"
          onChange={handleCustomerChange}
          value={newCustomer.firstName}
        />
        <label htmlFor="lastName">Last Name</label>
        <input
          id="lastName"
          name="lastName"
          type="text"
          onChange={handleCustomerChange}
          value={newCustomer.lastName}
        />
        <label htmlFor="email">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          onChange={handleCustomerChange}
          value={newCustomer.email}
        />
        <label htmlFor="contactNo">Contact No.</label>
        <input
          id="contactNo"
          name="contactNo"
          type="text"
          onChange={handleCustomerChange}
          value={newCustomer.contactNo}
        />
        <label htmlFor="jobTitle">Job Title</label>
        <input
          id="jobTitle"
          name="jobTitle"
          type="text"
          onChange={handleCustomerChange}
          value={newCustomer.jobTitle}
        />
        <label htmlFor="yearOfBirth">Year of Birth</label>
        <input
          type="text"
          id="yearOfBirth"
          name="yearOfBirth"
          onChange={handleCustomerChange}
          value={newCustomer.yearOfBirth}
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Adding ..." : "Add Customer"}
        </button>
      </form>
    </div>
  );
}

export default AddCustomer;
