import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { customerApi } from "../api/customerApi";

import CustomerTable from "../components/CustomerTable";
import Loading from "../components/Loading";
import Error from "../components/Error";

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    loadCustomers();
  }, []);

  const loadCustomers = async () => {
    try {
      setIsLoading(true);
      const response = await customerApi.get("/customers");
      setCustomers(response.data);
      setError(null);
    } catch (error) {
      console.log(error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loading />;
  if (error) return <Error message={error} />;

  return (
    <div>
      <h1>Customers</h1>
      <div style={{ marginBottom: 20 }}>
        <button onClick={() => navigate("/add-customer")}>
          Add a customer
        </button>
      </div>
      <CustomerTable customers={customers} />
    </div>
  );
}

export default Customers;
