function Home() {
  return (
    <div style={{ width: 600 }}>
      <h1>Welcome to Deliveranz</h1>
      <p>Are you a startup that has overcome the initial hurdle of viability?</p><br/>
      <p>Cautious about your next move as your first-mover advantage diminishes?</p><br/>
      <p>Many startup companies lose their vision in the bustle of their early- to mid-stage activities.</p><br/>
      <p>We are a boutique outfit with 15 years of corporate experience ranging from a promising local enterprise to a multi-national corporation with 100 years history.</p><br/>
      <p>We are also powered by a pipeline of the latest business and techology trends to provide you with the latest insights.</p><br/>
      <p>We specialise in SME startups looking to access corporate level tools and frameworks to guide their strategy during the growth stage of their company.</p><br/>
      <p>On-point delivery of real advice.</p><br/>
      <p>Deliveranz delivers.</p>
    </div>
  );
}

export default Home;
