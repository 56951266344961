import { Button } from '@react-email/button';

function Contact() {
  return (

      <div style={{ width: 600 }}>
        <h1>Contact Us</h1>
        <p>You can email us at</p><br/>
        <p><Button href="mailto:admin@deliveranz.com.sg?subject=Enquiry">admin@deliveranz.com.sg</Button></p><br/>
        <p> for all your boutique management consultancy needs. </p>
      </div>

  );
}

export default Contact;

// https://react.email/docs/utilities/render