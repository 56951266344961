import { NavLink } from "react-router-dom";

function CustomerTable({ customers }) {
  return (
    <table className="customer-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Contact No.</th>
          <th>Remarks</th>
          <th>Date</th>
          <th>Actions</th>

        </tr>
      </thead>
      <tbody>
        {customers.map((customer) => (
          <tr key={customer.id}>
            <td>
              <NavLink to={`/details/${customer.id}`}>
                {customer.firstName} {customer.lastName}
              </NavLink>
            </td>

            <td>{customer.email}</td>
            <td>{customer.contactNo}</td>
            <td>{customer.interactions}</td>
            <td>{customer.interactionDate}</td>           <td>
              <NavLink to={`/log-interaction/${customer.id}`}>
                📝 Log an Interaction
              </NavLink>
            </td>

          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default CustomerTable;
